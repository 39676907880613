import React, { forwardRef, useId } from 'react';
import SlickSlider, { Settings } from 'react-slick';
import styles from './index.module.scss';

type SliderProps = Settings & {
  className?: string;
  navClass?: string;
};

const Slider = forwardRef<SlickSlider, SliderProps>(function Slider(
  { children, className = '', navClass = null, ...props },
  ref,
) {
  const domUid = `slider--instance-${useId().replace(/:/g, '')}`;

  const addNavClass = () => {
    if (navClass) {
      document.querySelectorAll(`.${domUid} .slick-arrow`).forEach((element) => {
        if (!element.classList.contains(navClass)) {
          element.classList.add(navClass);
        }
      });
    }
  };

  return (
    <SlickSlider
      ref={ref}
      className={`${styles['slider']} ${className} ${navClass ? domUid : ''}`}
      onReInit={addNavClass}
      {...props}
    >
      {children}
    </SlickSlider>
  );
});

export default Slider;
