import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { DefaultPicture as Props } from '../../../types';

import styles from './index.module.scss';

export type DefaultPictureSource = {
  srcSet?: string;
  media?: string;
  type?: string;
};

export type DefaultPicturePreload = {
  imageSrcSet?: string;
  href?: string;
  media?: string;
  type?: string;
};

/**
 *
 * @param className
 * @param sources
 * @param fallbackSrc
 * @param alt
 * @param loading
 * @param preload
 * @param width
 *   Has a value by default (can be changed to any other), that can be overridden via CSS or passed explicitly
 * @param height
 *   Has a value by default (can be changed to any other), that can be overridden via CSS or passed explicitly
 * @param props
 * @constructor
 */
const DefaultPicture = ({
  className = '',
  sources,
  fallbackSrc,
  alt = '',
  loading,
  preload,
  width = 400,
  height = 200,
  ...props
}: Props) => {
  if (!sources || !sources.length) {
    return null;
  }

  if (preload) {
    return (
      <>
        <Head>
          {preload.map((data) => (
            <link
              rel="preload"
              href={data.href}
              imageSrcSet={data.imageSrcSet}
              as="image"
              type={data.type}
              media={data.media}
              key={data.imageSrcSet}
            />
          ))}
        </Head>
        <picture className={`default-picture ${className} ${styles['default-picture']}`}>
          {sources.map((source) => (
            <source {...source} key={source.srcSet} />
          ))}
          <img
            src={fallbackSrc}
            alt={alt ?? ''}
            width={width}
            height={height}
            loading="eager"
            {...props}
          />
        </picture>
      </>
    );
  }

  return (
    <picture className={`default-picture ${className} ${styles['default-picture']}`}>
      {sources.map((source, index) => (
        <source {...source} key={`${source.srcSet}-${index}`} />
      ))}
      <img
        src={fallbackSrc}
        width={width}
        height={height}
        alt={alt ?? ''}
        loading={loading}
        {...props}
      />
    </picture>
  );
};

// propTypes sits here only for compatibility purposes.
const propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string,
      media: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string,
  preload: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrcSet: PropTypes.string,
      href: PropTypes.string,
      media: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

export { propTypes };
export default DefaultPicture;
